import React from 'react';
import { motion } from 'framer-motion';
import styled, { keyframes } from 'styled-components';
import { FlipWords } from './FlipWords'; // Import the FlipWords component

// Keyframes for the background animation, creating a subtle wave effect
const waveAnimation = keyframes`
  0% { background-position: 0% 50%; }
  50% { background-position: 100% 50%; }
  100% { background-position: 0% 50%; }
`;

// Styled component for the Hero section
const HeroSection = styled.section`
  font-family: 'Lato', sans-serif;
  height: auto; // Set height to auto to adapt to content
  min-height: 50vh; // Ensure it still fills at least half of the viewport height
  display: flex;
  flex-direction: column; // Arrange children in a column
  justify-content: center; // Center content vertically
  align-items: center; // Center content horizontally
  padding: 60px 20px; // Add padding to reduce visual height and better spacing
  background-image: ${({ theme }) =>
    theme.body === '#FFFFFF'
      ? 'linear-gradient(to bottom right, #0066cc, #3399ff)' // Light theme gradient
      : 'linear-gradient(to bottom right, #333333, #111111)'}; // Dark theme gradient
  background-size: 200% 200%; // Make the background larger for animation effect
  animation: ${waveAnimation} 10s ease infinite; // Infinite animation for background
  color: ${({ theme }) => theme.text}; // Set text color based on theme
  text-align: center; // Center align text
  box-shadow: 0 8px 30px rgba(0, 0, 0, 0.3); // Make shadow stronger for more depth
  width: 100%;
  max-width: 1600px; // Limit width for larger screens
  margin: 0 auto; // Center the section horizontally
  border-radius: 8px; // Add subtle roundness to the corners
`;

// Styled component for the main hero text
const HeroText = styled(motion.h1)`
  font-size: 2.5rem; // Font size for main heading
  margin-bottom: ${({ isSecondLine }) => (isSecondLine ? '8px' : '16px')}; // Adjust margin based on whether it's the first or second line
  color: ${({ theme }) => theme.text}; // Set text color based on theme
  @media (max-width: 768px) {
    font-size: 2rem; // Reduce font size for smaller screens
  }
`;

// Styled component for the subtext that flips through different words
const HeroSubText = styled.div`
  font-size: 1.2rem; // Font size for subtext
  max-width: 90%; // Limit the width to prevent overflow
  margin-bottom: 20px; // Margin for spacing below
  position: relative;
  height: 2rem; // Fixed height to accommodate flipping words
  display: flex;
  align-items: center;
  justify-content: center;
  @media (max-width: 768px) {
    font-size: 1rem; // Reduce font size for smaller screens
  }
`;

// Styled component for the call-to-action button
const CallToAction = styled(motion.button)`
  font-family: 'Lato', sans-serif;
  background-color: ${({ theme }) => theme.primary}; // Button background color based on theme
  color: #fff; // White text color for better contrast
  padding: 10px 20px; // Padding for button size
  border: none;
  border-radius: 4px; // Rounded corners for the button
  font-size: 1rem; // Font size for button text
  cursor: pointer; // Pointer cursor to indicate clickability
  transition: background-color 0.3s ease; // Smooth transition for background color

  &:hover {
    background-color: ${({ theme }) => theme.primaryHover}; // Change background color on hover
  }
  @media (max-width: 768px) {
    padding: 8px 16px; // Reduce padding for smaller screens
    font-size: 0.9rem; // Reduce font size for smaller screens
  }
`;

// Main Hero component
const Hero = ({ theme }) => {
  // List of words to be used in the FlipWords component
  const words = [
    'Full Stack Developer',
    'React Enthusiast',
    'Cybersecurity Specialist',
    'Creative Problem Solver',
    'Freelance Photographer',
  ];

  return (
    <HeroSection theme={theme}>
      {/* Main heading with animation */}
      <HeroText
        initial={{ y: -200 }} // Initial position above the view
        animate={{ y: 0 }} // Animate to its final position
        transition={{ delay: 0.2, type: 'spring', stiffness: 120 }} // Spring animation with delay
      >
        Hi, I'm Salam
      </HeroText>
      {/* Second line of heading with animation */}
      <HeroText
        isSecondLine
        initial={{ y: -200 }} // Initial position above the view
        animate={{ y: 0 }} // Animate to its final position
        transition={{ delay: 0.4, type: 'spring', stiffness: 90 }} // Spring animation with delay
      >
        A
      </HeroText>
      {/* Subtext with flipping words */}
      <HeroSubText>
        <FlipWords words={words} duration={3000} /> {/* FlipWords component to cycle through different words */}
      </HeroSubText>
      {/* Call-to-action button with animation */}
      <CallToAction
        whileHover={{ scale: 1.1 }} // Scale up when hovered
        whileTap={{ scale: 0.9 }} // Scale down when clicked
        onClick={() => document.getElementById('contact').scrollIntoView({ behavior: 'smooth' })} // Smooth scroll to contact section
        theme={theme}
      >
        Get in Touch
      </CallToAction>
    </HeroSection>
  );
};

export default Hero;